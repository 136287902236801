@font-face {
  font-family: 'book';
  src: local('Solzl-Book'), url(./assets/font/Solzl/Stolzl-Book.ttf) format('truetype');
}
@font-face {
  font-family: 'bold';
  src: local('Stolzl-Bold'), url(./assets/font/Solzl/Stolzl-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'light';
  src: local('Stolzl-Light'), url(./assets/font/Solzl/Stolzl-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'medium';
  src: local('Stolzl-Medium'), url(./assets/font/Solzl/Stolzl-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'regular';
  src: local('Stolzl-Regular'), url(./assets/font/Solzl/Stolzl-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'thin';
  src: local('Stolzl-Thin'), url(./assets/font/Solzl/Stolzl-Thin.ttf) format('truetype');
}
